@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Share+Tech&display=swap");
@import "animate.css";

$share-tech: "Share Tech";

body {
    margin: 0;
    font-family:
        $share-tech,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    code {
        font-family:
            source-code-pro,
            Menlo,
            Monaco,
            Consolas,
            "Courier New",
            monospace;
    }

    #particles-js {
        height: 100vh;
    }

    @media (max-width: 767px) {
        #particles-text-morph {
            width: 100% !important;
        }
    }
}
