@use "./../../Helpers/helpers";

#side-content {
    color: inherit;
    position: absolute;
    z-index: 10000;
    height: 100%;
    background: rgb(0 0 0 / 85%);
    width: 50%;
    right: 0;
    padding: 5rem 2rem;

    .title {
        padding: 15px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 2px;
    }

    .content {
        text-align: left;
        overflow: auto;
        height: calc(100% - 2rem);
        padding: 15px;

        p {
            color: #fff;
        }

        &::-webkit-scrollbar {
            width: 0.1em;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }

    .close {
        cursor: pointer;
        position: relative;
        top: -2.2rem;
        right: 2rem;
        background: transparent;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        display: flex;
        align-content: space-around;
        opacity: 1;
        border: 1px solid #fff;

        &:hover {
            background-color: #fff;

            .self-close {
                color: #444;
            }
        }

        .self-close {
            font-size: 18px;
            width: 100%;
            height: 100%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@include helpers.for-size(tablet-portrait-down) {
    #side-content {
        width: calc(100% - calc(15px * 2));
        height: calc(100% - calc(15px * 2));
        margin: 15px;
        border-radius: 15px;
    }
}
