// USE: @include for-size(phone-only) {...}

@mixin for-size($size) {
    @if $size == phone-only {
        @media (max-width: 599px) {
            @content;
        }
    }

    @else if $size == tablet-portrait-down {
        @media (max-width: 900px) {
            @content;
        }
    }

    @else if $size == tablet-landscape-down {
        @media (max-width: 1400px) {
            @content;
        }
    }

    @else if $size == desktop-up {
        @media (min-width: 1401px) {
            @content;
        }
    }
}
