$services: (
    1: 4s,
    2: 6s,
    3: 8s,
    4: 10s,
);

#main {
    color: inherit;

    .service-effect {
        @each $child, $duration in $services {
            &:nth-child(#{$child}) {
                padding: 15px;

                --animation-delay: 0s;
                --animate-duration: #{$duration};
            }
        }
    }
}

.main-title {
    margin-bottom: 3.25rem;
    margin-top: 5rem;
    color: #fff;
    font-size: 4rem;
    display: flex;
    align-items: flex-start;
    animation-duration: 6s;
}

@media only screen and (max-width: 600px) {
    .special-padding {
        margin-bottom: 40px;
    }
}
